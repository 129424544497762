<template>
  <main class="tcsa d-flex flex-column my-2 mx-auto text-white text-left">
    <h1 class="sr-only">Soca's Ethical Use</h1>
    <div class="tcsa__content mb-4">
      <section class="tcsa__sub-content">
        <h2 class="tcsa__title mb-4">Soca's Ethical Use</h2>
        <ol start="0" class="tcsa__list-1 d-flex flex-column">
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              We believe in promoting responsible and ethical use of our website, which utilizes AI technology to generate text-to-audio content and employs AI characters. To ensure a positive and respectful user experience, we have implemented a sentiment analysis system that identifies and filters out profanity words. We kindly ask our users to adhere to the following ethical guidelines:
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Respectful Communication:</h3>
            <p class="tcsa__item-description">
              Interact with our AI characters and other users in a respectful and considerate manner. Refrain from using offensive language, engaging in personal attacks, or promoting hate speech.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Lawful Content:</h3>
            <p class="tcsa__item-description">
              Do not use our platform to generate, share, or promote any content that violates local, national, or international laws. This includes, but is not limited to, content that is defamatory, discriminatory, harassing, or infringing upon intellectual property rights.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Privacy and Data Protection:</h3>
            <p class="tcsa__item-description">
              Safeguard your personal information and respect the privacy of others. Do not attempt to access or share private information without proper authorization. Be mindful of the data you provide while interacting with our website.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Content Ownership:</h3>
            <p class="tcsa__item-description">
              Respect intellectual property rights by not using our platform to generate or distribute content that infringes upon copyrights or trademarks. Obtain proper permissions or licenses before using any copyrighted material.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Prohibited Uses:</h3>
            <p class="tcsa__item-description">
              Do not employ our website for activities that are unethical, illegal, or harmful, such as spamming, hacking, spreading malware, or engaging in fraudulent behavior. Misuse of our platform may result in the termination of your account.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Feedback and Reporting:</h3>
            <p class="tcsa__item-description">
              If you come across any inappropriate or offensive content generated by our AI or encounter any misuse of the platform, please report it to our support team. Your feedback helps us maintain a safe and respectful environment for all users.
            </p>
          </li>
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              By using our website, you acknowledge and agree to abide by these ethical guidelines. We reserve the right to take appropriate action, including the suspension or termination of user accounts, to enforce these guidelines and ensure the integrity of our platform. Together, we can create a positive and inclusive community for everyone.
            </p>
          </li>
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              Regards,<br />
              Legal Team of Soca AI
            </p>
          </li>
        </ol>
      </section>
      <section class="tcsa__sub-content">
        <h2 class="tcsa__title mb-4">Penggunaan Etis Layanan Soca</h2>
        <ol start="0" class="tcsa__list-1 d-flex flex-column">
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              Kami percaya dalam mempromosikan penggunaan yang bertanggung jawab dan etis dari situs web kami, yang menggunakan teknologi AI untuk menghasilkan konten teks-ke-audio dan menggunakan karakter AI. Untuk menjamin pengalaman pengguna yang positif dan menghormati, kami telah menerapkan sistem analisis sentimen yang mengidentifikasi dan menyaring kata-kata kasar. Kami dengan hormat meminta pengguna kami untuk mematuhi pedoman etis berikut:
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Komunikasi yang Menghormati:</h3>
            <p class="tcsa__item-description">
              Berinteraksi dengan karakter AI kami dan pengguna lain dengan sopan dan mempertimbangkan. Hindari penggunaan bahasa yang menghina, melakukan serangan pribadi, atau mempromosikan ujaran kebencian.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Konten yang Sah:</h3>
            <p class="tcsa__item-description">
              Jangan menggunakan platform kami untuk menghasilkan, membagikan, atau mempromosikan konten yang melanggar hukum setempat, nasional, atau internasional. Ini termasuk, namun tidak terbatas pada, konten yang memfitnah, diskriminatif, melecehkan, atau melanggar hak kekayaan intelektual.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Privasi dan Perlindungan Data:</h3>
            <p class="tcsa__item-description">
              Lindungi informasi pribadi Anda dan menghormati privasi orang lain. Jangan mencoba mengakses atau membagikan informasi pribadi tanpa izin yang tepat. Perhatikan data yang Anda berikan saat berinteraksi dengan situs web kami.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Kepemilikan Konten:</h3>
            <p class="tcsa__item-description">
              Hormati hak kekayaan intelektual dengan tidak menggunakan platform kami untuk menghasilkan atau mendistribusikan konten yang melanggar hak cipta atau merek dagang. Dapatkan izin atau lisensi yang tepat sebelum menggunakan materi berhak cipta.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Penggunaan Dilarang:</h3>
            <p class="tcsa__item-description">
              Jangan menggunakan situs web kami untuk kegiatan yang tidak etis, ilegal, atau berbahaya, seperti spamming, peretasan, penyebaran malware, atau perilaku penipuan. Penyalahgunaan platform kami dapat mengakibatkan penghentian akun Anda.
            </p>
          </li>
          <li class="tcsa__item">
            <h3 class="tcsa__item-title">Umpan Balik dan Pelaporan:</h3>
            <p class="tcsa__item-description">
              Jika Anda menemui konten yang tidak pantas atau kasar yang dihasilkan oleh AI kami atau menemui penyalahgunaan platform, laporkan kepada tim dukungan kami. Umpan balik Anda membantu kami menjaga lingkungan yang aman dan menghormati bagi semua pengguna.
            </p>
          </li>
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              Dengan menggunakan situs web kami, Anda mengakui dan menyetujui untuk mematuhi pedoman etis ini. Kami berhak untuk mengambil tindakan yang sesuai, termasuk penangguhan atau penghentian akun pengguna, untuk menegakkan pedoman ini dan memastikan integritas platform kami. Bersama-sama, kita dapat menciptakan komunitas yang positif dan inklusif bagi semua orang.
            </p>
          </li>
          <li class="tcsa__item">
            <p class="tcsa__item-description">
              Salam Hormat,<br />
              Tim Hukum Soca AI
            </p>
          </li>
        </ol>
      </section>
    </div>
    <div class="tcsa__agreement d-flex align-items-center">
      <input
        type="checkbox"
        id="input-agree"
        class="tcsa__agreement-input"
        v-model="isAgree"
        title="Agreed the Terms and Conditions"
      >
      <label
        for="input-agree"
        class="tcsa__agreement-label m-0"
        title="Agreed the Terms and Conditions"
      >
        I have understood and agreed to the applicable terms and conditions.
      </label>
    </div>
    <div class="tcsa__actions d-flex justify-content-center align-items-center mt-4">
      <button
        type="button"
        class="tcsa__button-cancel btn px-3 text-white"
        title="Cancel"
        @click="handleClickCancel"
      >
        Cancel
      </button>
      <button
        type="button"
        class="tcsa__button-continue btn px-3 text-white"
        title="Continue"
        :disabled="!isAgree"
        @click="handleClickContinue"
      >
        Continue
      </button>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isAgree: false,
    };
  },
  methods: {
    handleClickCancel() {
      sessionStorage.removeItem('isAgreedSentiment');
      this.$router.back();
    },
    handleClickContinue() {
      sessionStorage.setItem('isAgreedSentiment', true);
      this.$router.back();
    },
  },
  mounted() {
    document.getElementById('main-container').style.margin = 0;
  },
};
</script>

<style scoped>
.tcsa {
  --gap: 12px;
  --main-container-padding-top: 80px;
  --main-container-padding-bottom: 40px;
  gap: var(--gap);
  height: calc(
    100vh
    - var(--main-container-padding-top)
    - var(--main-container-padding-bottom)
    - var(--gap)
    - 4px
  );
  max-width: 710px;
}

.tcsa__title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.tcsa__content {
  overflow-y: auto;
}

.tcsa__sub-content + .tcsa__sub-content {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.tcsa__list-1, .tcsa__list-2, .tcsa__list-3 {
  list-style: none;
}

.tcsa__list-1 {
  counter-reset: level-1;
  gap: 24px;
}

.tcsa__list-1 li > .tcsa__item-title {
  display: flex;
  gap: 8px;
}

.tcsa__list-1 li > .tcsa__item-title::before {
  counter-increment: level-1;
  content: counter(level-1)".";
}

.tcsa__list-2 {
  counter-reset: level-2;
}

.tcsa__list-2 li > .tcsa__item-description {
  display: flex;
  gap: 12px;
}

.tcsa__list-2 li > .tcsa__item-description::before {
  counter-increment: level-2;
  content: counter(level-1)"."counter(level-2);
}

.tcsa__list-3 {
  counter-reset: level-3;
  margin-top: 4px;
}

.tcsa__list-3 li > .tcsa__item-description {
  display: flex;
  gap: 12px;
}

.tcsa__list-3 li > .tcsa__item-description::before {
  counter-increment: level-3;
  content: "("counter(level-3)")";
}

.tcsa__item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tcsa__item-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
}

.tcsa__item-description {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
}

.tcsa__agreement {
  gap: 8px;
}

.tcsa__agreement-input {
  width: 15px;
  height: 15px;
  accent-color: #6D75F6;
}

.tcsa__agreement-label {
  font-size: 15px;
}

.tcsa__actions {
  gap: 8px;
}

.tcsa__button-cancel, .tcsa__button-continue {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  transition: background-color 0.25s;
}

.tcsa__button-cancel {
  background-color: #1F1F1F;
}

.tcsa__button-cancel:hover {
  background-color: #2C2C2C;
}

.tcsa__button-continue {
  background-color: #6D75F6;
}

.tcsa__button-continue:hover {
  background-color: #545EED;
}

.tcsa__button-continue[disabled] {
  background-color: #1F1F1F;
  color: #585859;
}
</style>
